<template>
  <div>
    <Navbar mode="solid" class="fixed w-full top-0" />
    <main class="page-main">
      <div class="wrapper">
        <h1 v-t="'page.covid19.title'"/>
        <p v-t="'page.covid19.content'"/>
      </div>
    </main>
    <Footer />
    <BottomBar class="block sm:hidden fixed bottom-0 w-full" />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import BottomBar from "@/components/layout/BottomBar.vue";
export default {
  name: "LegalNotice",
  components: { BottomBar, Navbar, Footer },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Travel Afrique – COVID-19',
  },
};
</script>
<style scoped>
.page-main {
  @apply w-full px-4 pt-36 pb-16;
}

.wrapper {
  @apply w-full max-w-screen-sm;
  @apply relative left-1/2 transform -translate-x-1/2;
}

h1,
h2,
h3 {
  @apply font-semibold text-black-base;
}

p {
  @apply text-black-base leading-normal;
  @apply mt-2 mb-4 whitespace-pre-line;
}

h1 {
  @apply text-3xl leading-tight;
}

h2 {
  @apply text-2xl leading-9;
  @apply mt-8 mb-2;
}

h3 {
  @apply mt-4 mb-2 text-lg;
}

</style>